import request from '@/utils/request'

export function getTransportType (params) {
  return request({
    url: '/api/transport/getTransportTypes',
    method: 'get',
    params
  })
}
export function getTransportTypeNoToken () {
  return request({
    url: '/api/transport/getTransportTypesNoToken',
    method: 'get'
  })
}
export function getMuDidiPortList () {
  return request({
    url: '/api/clientRequirement/getMuDidiPortList',
    method: 'get'
  })
}
