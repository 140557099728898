<template>
  <div class="Bmap">
    <div class="Bmap_close" @click="CloseDialog('', '', '')"><span>Close</span></div>
    <div id="Bmap_container"></div>
  </div>
</template>

<script setup name="BMap">
import { ref, onMounted, defineEmits, defineProps, watch } from 'vue'

const props = defineProps({
  startlocation: Array
})

// 关闭弹窗
const emit = defineEmits(['customEvent'])
const CloseDialog = (distance, address) => {
  emit('customEvent', { distance, address })
  console.log('距离', distance, 'KM')
}

// 初始化地图，设置中心点坐标和地图级别
const initMap = () => {
  var map = new Microsoft.Maps.Map(document.getElementById('Bmap_container'), {
    culture: 'en-US',
    locale: 'en-US',
    center: new Microsoft.Maps.Location(53.34749, -6.25276),
    zoom: 12,
    showDashboard: false
  })

  // 添加鼠标点击事件
  Microsoft.Maps.Events.addHandler(map, 'click', function (e) {
    // 获取鼠标点击的位置经纬度
    var point = new Microsoft.Maps.Point(e.getX(), e.getY())
    var clickLocation = e.target.tryPixelToLocation(point)
    // console.log('点击的经纬度', clickLocation.latitude, clickLocation.longitude)

    // ReverseGeocode 方法将经纬度 转换成 地址
    var deliveryAddress = ''
    Microsoft.Maps.loadModule('Microsoft.Maps.Search', function () {
      var searchManager = new Microsoft.Maps.Search.SearchManager(map)
      var reverseGeocodeRequestOptions = {
        location: new Microsoft.Maps.Location(clickLocation.latitude, clickLocation.longitude),
        callback: function (answer, userData) {
          map.setView({ bounds: answer.bestView })
          map.entities.push(new Microsoft.Maps.Pushpin(reverseGeocodeRequestOptions.location))
          deliveryAddress = answer.address.addressLine
          // console.log('点击地图经纬度转换地点', deliveryAddress)
        }
      }
      searchManager.reverseGeocode(reverseGeocodeRequestOptions)
    })

    // DirectionsManager 计算路线
    Microsoft.Maps.loadModule('Microsoft.Maps.Directions', function () {
      var directionsManager = new Microsoft.Maps.Directions.DirectionsManager(map)
      var wp1 = new Microsoft.Maps.Directions.Waypoint({
        location: new Microsoft.Maps.Location(props.startlocation[0], props.startlocation[1])
      })
      var wp2 = new Microsoft.Maps.Directions.Waypoint({
        location: new Microsoft.Maps.Location(clickLocation.latitude, clickLocation.longitude)
      })

      // 添加起点和终点
      directionsManager.addWaypoint(wp1)
      directionsManager.addWaypoint(wp2)

      // 计算路线
      directionsManager.calculateDirections()

      // directionsUpdated事件
      Microsoft.Maps.Events.addHandler(directionsManager, 'directionsUpdated', function () {
        var route = directionsManager.getRouteResult()
        // console.log(route[0].routeLegs[0].summary.distance, 'KM')
        CloseDialog(route[0].routeLegs[0].summary.distance, deliveryAddress)
      })
    })
  })
}

onMounted(() => {
  initMap()
})

</script>

<style lang="scss" scoped>
.Bmap {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
  line-height: 1;
  text-align: left;
  overflow-y: scroll;

  &_close {
    width: 1000px;
    height: 30px;
    margin: 60px auto 0;
    text-align: right;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    color: rgba(255, 255, 255, 1);
    z-index: 1 !important;

    span {
      background-color: rgba(242, 90, 8, 1);
      padding: 5px 10px;
      border-radius: 4px;
      margin-right: 10px;
    }
  }

  #Bmap_container {
    width: 1000px;
    height: 500px;
    margin: -45px auto 50px;
    z-index: -1;
  }
}
</style>
